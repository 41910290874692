import React from "react";
import { Row, Col, Container, Button} from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";

import Divider from '../../sections/akkadian/Divider';
import imgBanner from "../../assets/image/akkadian/teamworking.jpg";
import { Helmet } from "react-helmet";
import { Link } from 'gatsby';
import { clinicians, states } from "../../utils/team.js";


const NT = () => {
  const localState = "NT";
  const localState_long = "Northern Territory";
  const localState_maincity = "Darwin";
  const main_string = "Akkadian Health offers telehealth Services in Darwin, Alice Springs and throughout the NT.";
  const secondary_string = "Our clinical team has grown to include dozens of experienced psychiatrists, psychologists and mental health clinicians all driven by the same goal - to make a population impact on the mental health issues facing everyday Australians.";

  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          buttonText: "Contact us"
        }}
        footerConfig={{
          theme: "light",
          page: "akkadian-home",
          style: "style2", //style1, style2
        }}
      >
        <Helmet>
        <title>Akkadian Health - Psychiatrists in {localState_long} | ADHD Assessments in {localState_maincity} </title>
        <meta
                name="description"
                content="Akkadian Health offers ADHD assessments and psychiatric services in {localState_long} via telehealth. Serving {localState_maincity} and all regional areas."
            />
        </Helmet>
				  <style>{`
					table{
					 width:90%;
					}
					table, th, td {
					  border: 1px solid white;
					  border-collapse: collapse;
					  padding: 8px;
					  color: white;
					  margin-left: 5%
					}
					th, td {
					  background-color: #2fbfcf;
					}
				  `}</style>

        <div className="inner-banner">
            <Container>

            <Row id="about-us" className="justify-content-center mt-md-3 pt-24 pt-lg-29">
					<Col lg="9" xl="8">
						<div className="px-md-6 text-center mb-11 mb-lg-14">
							<h1 className="title gr-text-3 mb-9 mb-lg-12">Our Services in {localState_long}</h1>
                        </div>
                    </Col>
            </Row>

			<Row className="justify-content-center align-items-center">
				<Col md="12">
					<div className="section-title text-center mb-12 mb-lg-16">
						<p className="gr-text-10 px-lg-8 mb-0">
							{main_string}
						</p>
						<br />
						<p className="gr-text-10 px-lg-8">
                            {secondary_string}
						</p>                            
					</div>
				</Col>
			</Row>					
				
            {clinicians && clinicians.filter(clinician => clinician.state === localState).length > 0 && (
            <Row className="gr-text-8 mb-8 px-4">
                <h4>Meet our local {localState} clinical team</h4>
                <hr />            
            </Row>    
            )}

				{clinicians && clinicians.filter(clinician => clinician.state === localState).map(clinician => (
					<Row key={clinician.id} className="gr-text-8 mb-8 px-4">
                    <Link to={`/our-team`} state={{provider: `${clinician.id}`}} partiallyActive={true}>
                    <Button
                            variant="outline-secondary"
                            style={{backgroundColor: states.find(state => state.name === localState).color, borderColor: states.find(state => state.name === localState).color, color: states.find(state => state.name === localState).textColor, fontWeight: "bold"}}
                            className="mr-2 mb-2"
                        >
                            {clinician.name}
                        </Button>
                    </Link>
                    </Row>
				))}
				
				<hr />


                <Row className="justify-content-center pt-10 pt-lg-17">
                        <Link to="/for-specialists" className={`btn btn-primary gr-hover-y`}>
                            Join us
                        </Link>
                </Row>
                <br />
            </Container>
        </div>
        <Divider />

				
				
			
      </PageWrapper>
    </>
		
		
    );
};

export default NT;